<template>
  <div class="contaner_w">
    <new-header :config="headerConfig"></new-header>

    <div class="content_record">
      <div class="header_box">
        <div @click="selectBtn(1)" :class="select ? 'bar' : ''">实物合同</div>
        <div @click="selectBtn(2)" :class="select ? '' : 'bar'">电子合同</div>
      </div>

      <div class="record_box">
        <div
          class="record_list"
          v-for="(item, index) in dataList"
          :key="index"
          @click="goDetails(item.audit_id, item)"
        >
          <div class="date_box">
            <div>
              申请时间：<span>{{ item.create_time }}</span>
            </div>
            <div>
              物料数量：<span>{{
                item.audit_info ? item.audit_info.length : item.audit_contract.length
              }}</span>
            </div>
            <div>当前流程：<span>物料审核</span></div>
          </div>
          <div v-if="item.audit_state < 6 && item.close_state == 0">
            <div class="status_text">进行中...</div>
            <div class="look_item">查看详情 ></div>
          </div>
          <div v-if="item.audit_state == 6 && item.close_state == 0">
            <div class="status_text1">已完成</div>
            <div class="look_item">查看详情 ></div>
          </div>
          <div v-if="item.close_state == 1">
            <div class="status_text2">已拒绝</div>
            <div class="look_item">查看详情 ></div>
          </div>
        </div>

        <van-empty v-if="dataList.length==0"
          class="custom-image"
          image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
          description="暂无数据"
        />
      </div>
    </div>
  </div>
</template>
<script type>
import newHeader from "@/components/newHeader";
import { sharesChange_list_new_api, sharesChange_list_new_s_api } from "@/api/admin";
export default {
  components: {
    newHeader,
  },
  data() {
    return {
      headerConfig: {
        show: true,
        title: '物料中心',
      },
      show: false,
      select: true,
      index: 1,
      radioConfig: {
        value: "",
        list: [
          { checked: true, title: "增值兑换", ladel: 1 },
          { checked: false, title: "分享补金兑换", ladel: 2 },
        ],
      },
      queryList: {
        page: 1,
        limit: 10,
      },
      dataList: [],
    };
  },
  created() {
    document.title = "物料中心";
    this.getList();
  },
  methods: {
    getList() {
      sharesChange_list_new_api(this.queryList).then((res) => {
        if (res.code == 0) {
          if (this.headerBtnShow) {
            this.total = res.pagination.total;
          }
          this.dataList = this.dataList.concat(res.data);
        }
      });
    },

    getListNew() {
      sharesChange_list_new_s_api(this.queryList).then((res) => {
        if (res.code == 0) {
          if (this.headerBtnShow) {
            this.total = res.pagination.total;
          }
          this.dataList = this.dataList.concat(res.data);
        }
      });
    },
    submitBtn() {
      this.show = true;
    },
    selectBtn(index) {
      this.index = index;
      this.queryList.page = 1;
      this.dataList = [];
      if (index == 1) {
        this.select = true;
        this.getList();
      } else {
        this.select = false;
        this.getListNew();
      }
    },
    goDetails(id, item) {
      if (this.index == 1) {
        if (item.pay_info[0].order_id) {
          this.$router.push(`./nextAuditNew?id=${id}`);
        } else {
          this.$router.push(`./nextAudit?id=${id}`);
        }
      } else {
        this.$router.push(`./nextAuditNew?id=${id}`);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.contaner_w {
  background-color: #f9f9fb;
}

.content_record {
  padding: 30px;
  box-sizing: border-box;

  .record_list {
    width: 690px;
    height: 200px;
    background: linear-gradient(180deg, #ffdd6f 0%, #ffffff 89%);
    box-shadow: 0px 2px 12px 0px rgba(146, 146, 146, 0.1);
    border-radius: 8px 8px 8px 8px;
    margin-top: 33px;
    padding: 28px 26px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 28px;
    color: #1a1a1a;

    .date_box {
      div > {
        margin-bottom: 23px;
      }

      span {
        color: rgba(26, 26, 26, 0.7);
      }
    }

    .status_text {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #ff8a00;
      margin-bottom: 70px;
    }

    .status_text1 {
      color: #4eb84a;
      margin-bottom: 70px;
    }

    .status_text2 {
      color: #ff3232;
      margin-bottom: 70px;
    }

    .look_item {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #0091ff;
    }
  }

  .header_box {
    width: 690px;
    height: 72px;
    background: #ffffff;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 28px;
    color: rgba(26, 26, 26, 0.8);
    text-align: center;
    line-height: 72px;

    div {
      width: 345px;
      height: 72px;
      background: #fff;
      border-radius: 10px 10px 10px 10px;
    }

    .bar {
      width: 345px;
      height: 72px;
      background: linear-gradient(180deg, #252324 0%, #3c3633 100%), #0091ff;
      box-shadow: 4px 0px 6px 0px rgba(4, 70, 169, 0.15);
      border-radius: 10px 10px 10px 10px;
      color: #fff;
    }
  }
}
</style>
